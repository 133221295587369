import ordersApi from 'features/orders/orders.api';
import { useOrderPresentation } from 'features/orders/providers/OrderPresentation';
import QuoteDetailModal from 'features/quotes/components/QuoteDetailModal/QuoteDetailModal';
import quotesApi from 'features/quotes/quotes.api';
import React, { useCallback } from 'react';
import { Outlet, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { usePageTitle } from 'utils/locationHelpers';

export default function QuoteDetailPage() {
    const { orderId } = useParams() as { orderId: string };
    return (
        // Use a separate comp so we can key it based on the orderId
        // this prevents cross-contamination of data when switching between quotes
        <QuoteDetailPagePure
            key={orderId}
            orderId={parseInt(orderId, 10)}
        />
    );
}

function QuoteDetailPagePure({ orderId }: { orderId: number }) {
    const { routeBase } = useOrderPresentation();
    const navigate = useNavigate();
    const [urlParams] = useSearchParams();

    const orderQuery = quotesApi.useQuoteDetailQuery(orderId);
    usePageTitle(orderQuery.data?.unique_id);
    const windowsQuery = ordersApi.useOrderWindowListQuery(orderId);

    const handleClose = useCallback(() => {
        // Clear tab when closing modal
        urlParams.delete('tab');
        navigate(`${routeBase}?${urlParams}`);
    }, [navigate, routeBase, urlParams]);

    return orderId ? (
        <>
            <QuoteDetailModal
                model={orderQuery.data}
                windows={windowsQuery.data}
                isLoading={orderQuery.isLoading || windowsQuery.isLoading}
                isError={orderQuery.isError || windowsQuery.isError}
                close={handleClose}
            />
            <Outlet />
        </>
    ) : null;
}
