import QuoteCreateModal from 'features/quotes/components/QuoteCreateModal/QuoteCreateModal';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { usePageTitle } from 'utils/locationHelpers';

export default function QuoteCreatePage() {
    usePageTitle('Create');
    const navigate = useNavigate();

    return <QuoteCreateModal close={() => navigate(`/quotes`)} />;
}
