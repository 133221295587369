import { QuoteEditable } from './QuoteEditable';

export type QuoteCreate = QuoteEditable & {
    is_draft: boolean;
};

export class QuoteCreateFactory {
    static create(
        data?: Partial<QuoteCreate> & {
            company_id: number;
            employee_id: number | null;
        },
    ): QuoteCreate {
        const defaults: QuoteCreate = {
            id: 0,
            company_id: 0,
            employee_id: 0,
            details: '',
            is_draft: false,
        };

        const mergedData = { ...defaults, ...data };

        return mergedData;
    }
}
