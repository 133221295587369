import z from 'zod';
import { ManufacturerOrderSchema } from './ManufacturerOrder';

export const PurchaseOrderOrderingFormSchema = z.object({
    id: z.number(),
    order_id: z.number(),
    brand_id: z.number().nullable(),
    category_id: z.number().nullable(),
    purchase_order_id: z.string(),
    manufacturer_orders: z.array(ManufacturerOrderSchema),
    supplier_order_number: z.string().nullable(),
});

export const PurchaseOrderOrderingInfoSchema = z.object({
    id: z.number(),
    forms: z.array(PurchaseOrderOrderingFormSchema),
});

export type PurchaseOrderOrderingForm = z.infer<typeof PurchaseOrderOrderingFormSchema>;
export type PurchaseOrderOrderingInfo = z.infer<typeof PurchaseOrderOrderingInfoSchema>;
