import Icons from 'Icons';
import DataTableFilters, {
    DataTableFilterConfig,
} from 'components/DataTableFilters/DataTableFilters';
import SearchFilter from 'components/DataTableFilters/Filters/SearchFilter';
import MyButton, { MyButtonLinkNewTab } from 'components/MyButton/MyButton';
import PropertyContainer from 'components/PropertyContainer/PropertyContainer';
import PropertyDisplay from 'components/PropertyDisplay/PropertyDisplay';
import {
    orderProductSort,
    useProductGroupOptions,
} from 'features/orders/helpers/orderProductHelper';
import { validateProducts } from 'features/orders/helpers/orderValidationHelper';
import { OrderWindow } from 'features/orders/models/OrderWindow';
import { OrderWindowProduct } from 'features/orders/models/OrderWindowProduct';
import { QuoteDetail } from 'features/quotes/models/QuoteDetail';
import { useBreakpoints } from 'providers/Breakpoints';
import React, { useMemo, useState } from 'react';
import { formatCurrency } from 'utils/helpers';
import OrderProductAddModal from '../../../orders/components/OrderProductAddModal/OrderProductAddModal';
import { OrderProductRow } from '../../../orders/components/OrderProductRow/OrderProductRow';
import QuoteEditModal from '../QuoteEditModal/QuoteEditModal';
import QuotePriceEditModal from '../QuotePriceEditModal/QuotePriceEditModal';
import QuotePdfModal from '../QuoteReviewModal/QuotePdfModal';
import QuoteStatusBadge from '../QuoteStatusBadge/QuoteStatusBadge';
import './QuoteDetailTab.scss';

// Quote (quotes)
export default function QuoteDetailTab({
    model,
    windows,
}: {
    model: QuoteDetail;
    windows: OrderWindow[];
}) {
    const breakpoints = useBreakpoints();

    const [showEditModal, setShowEditModal] = useState(false);
    const [showPriceEditModal, setShowPriceEditModal] = useState(false);
    const [showPdfModal, setShowPdfModal] = useState(false);

    /** Total number of products */
    const totalQuantity = model?.final_price[0]?.products_selected_with_quantity ?? 0;

    /** Calculated total applied to this order (ignorning overrideTotal) */
    const totalPrice = model?.final_price[0]?.total ?? 0;

    /** The user-entered override total (Sales only) */
    const overrideTotal = model?.final_price[0]?.override_total;

    return (
        <div className="QuoteDetailTab">
            <div className="QuoteDetailTab__DetailsPanel">
                <PropertyContainer layout="table">
                    <PropertyDisplay
                        label="Details"
                        verticalAlign="top"
                        value={model.details}
                    />

                    <PropertyDisplay
                        label="Status"
                        verticalAlign="top"
                        value={
                            <div className="QuoteDetailTab__StatusBadges">
                                {model?.is_archived === 1 && <ArchivedBadge />}
                                <QuoteStatusBadge model={model} />
                            </div>
                        }
                    />
                </PropertyContainer>

                {!model.isReadOnly && (
                    <MyButton
                        className="QuoteDetailTab__DetailsPanel__EditButton"
                        label="Edit"
                        IconLeft={Icons.Edit}
                        buttonType="Hollow"
                        onClick={() => setShowEditModal(true)}
                    />
                )}
            </div>

            <ProductList
                order={model}
                windows={windows}
                isReadOnly={model.isReadOnly}
            />

            {totalQuantity > 0 && (
                <div className="QuoteDetailTab__Footer">
                    <div className="QuoteDetailTab__Footer__Content">
                        <div className="QuoteDetailTab__Footer__Content__TotalQuantity">
                            {totalQuantity} {totalQuantity === 1 ? 'product' : 'products'}, total
                        </div>
                        {overrideTotal !== null && (
                            <div className="QuoteDetailTab__Footer__Content__OriginalPrice">
                                {formatCurrency(totalPrice)}
                            </div>
                        )}

                        <div className="QuoteDetailTab__Footer__Content__Price">
                            {formatCurrency(overrideTotal ?? totalPrice)}

                            {/* Edit price button for quotes only */}
                            {!model.isReadOnly && (
                                <MyButton
                                    className="QuoteDetailTab__Footer__Content__Price__EditButton"
                                    IconLeft={Icons.Edit}
                                    title="Edit price"
                                    buttonType="None"
                                    size="small"
                                    onClick={() => setShowPriceEditModal(true)}
                                />
                            )}
                        </div>
                    </div>
                    <MyButton
                        label="View PDF"
                        buttonType="Hollow"
                        LinkComponent={MyButtonLinkNewTab}
                        onClick={() => setShowPdfModal(true)}
                        IconLeft={Icons.File}
                        fullWidth={breakpoints.isVerySmallOnly}
                    />
                </div>
            )}

            {showEditModal && (
                <QuoteEditModal
                    order={model}
                    close={() => setShowEditModal(false)}
                />
            )}
            {showPriceEditModal && (
                <QuotePriceEditModal
                    order={model}
                    close={() => setShowPriceEditModal(false)}
                />
            )}
            {showPdfModal && (
                <QuotePdfModal
                    model={model}
                    windows={windows}
                    close={() => setShowPdfModal(false)}
                />
            )}
        </div>
    );
}

function ArchivedBadge() {
    return <div className="QuoteDetailTab__ArchivedBadge">Archived</div>;
}

function ProductList({
    order,
    windows,
    isReadOnly,
}: {
    order: QuoteDetail;
    windows: OrderWindow[];
    isReadOnly?: boolean;
}) {
    const [filteredProducts, setFilteredProducts] = useState<OrderWindowProduct[]>();
    const [showAddProductModal, setShowAddProductModal] = useState(false);

    const breakpoints = useBreakpoints();

    const allProducts = useMemo(
        () =>
            windows
                ?.flatMap(w => w.window_products)
                .filter(p => !!p.is_selected)
                .sort(orderProductSort) ?? undefined,
        [windows],
    );

    const labelOptions = useProductGroupOptions(order.id);
    const filters: DataTableFilterConfig[] = useMemo(() => {
        return [
            {
                label: 'Search',
                type: 'search',
                fields: ['master_products_name', 'products_name', 'details'],
                applyFilter: (filter, value, item: OrderWindowProduct) => {
                    const fields = [
                        item.master_products_name ? 'master_products_name' : 'products_name',
                        'details',
                    ];
                    return SearchFilter.applyFilter(
                        {
                            ...filter,
                            type: 'search',
                            fields,
                        },
                        value,
                        item,
                    );
                },
            },
            {
                label: 'Filter',
                type: 'select',
                field: 'filter',
                options: [
                    {
                        label: 'All products',
                        value: 'all',
                    },
                    {
                        label: 'With errors',
                        value: 'problems',
                    },
                ],
                defaultValue: 'all',
                allowBlank: false,
                applyFilter: (filter, value, item) => {
                    if (value === 'problems') {
                        const errors = validateProducts([item]);
                        return errors.length > 0;
                    }
                    return true;
                },
            },
            {
                label: 'Window or group',
                type: 'select',
                field: 'details',
                options: [
                    { label: '(None)', value: '__NO_LABEL', isPlaceholder: true },
                    ...labelOptions,
                ],
                defaultValue: '',
                allowBlank: true,
                applyFilter: (filter, value, item: OrderWindowProduct) => {
                    if (value === '__NO_LABEL') {
                        return !item.details;
                    }
                    return item.details?.startsWith(value) ?? false;
                },
            },
        ];
    }, [labelOptions]);

    return (
        <div className="QuoteDetailTab__ProductList">
            {allProducts?.length === 0 ? (
                <div className="QuoteDetailTab__ProductList__Empty">
                    {isReadOnly ? (
                        <label>No products added</label>
                    ) : (
                        <>
                            <label>No products added yet</label>
                            <MyButton
                                label="Add product"
                                IconLeft={Icons.Plus}
                                buttonType="Accent"
                                onClick={() => setShowAddProductModal(true)}
                            />
                        </>
                    )}
                </div>
            ) : (
                <>
                    <div className="QuoteDetailTab__ProductList__Header">
                        <h2>Products</h2>
                        <div className="QuoteDetailTab__ProductList__Header__Actions">
                            {!isReadOnly && (
                                <MyButton
                                    className="QuoteDetailTab__ProductList__Toolbar__AddButton"
                                    label={'Add'}
                                    IconLeft={Icons.Plus}
                                    buttonType="Accent"
                                    onClick={() => setShowAddProductModal(true)}
                                    fullWidth={breakpoints.isVerySmallOnly}
                                />
                            )}
                        </div>
                    </div>
                    <div className="QuoteDetailTab__ProductList__Toolbar">
                        <DataTableFilters
                            filters={filters}
                            data={allProducts}
                            onChange={setFilteredProducts}
                        />
                    </div>
                    {(filteredProducts?.length ?? 0) === 0 ? (
                        <div className="QuoteDetailTab__ProductList__ProductsFilteredOut">
                            No products in this order matched your search.
                        </div>
                    ) : (
                        <div className="QuoteDetailTab__ProductList__Products">
                            {filteredProducts?.map(p => (
                                <OrderProductRow
                                    key={p.id}
                                    order={order}
                                    product={p}
                                    showManufacturerStatus={true}
                                />
                            ))}
                        </div>
                    )}
                </>
            )}

            {showAddProductModal && (
                <OrderProductAddModal
                    orderId={order.id}
                    windows={windows}
                    close={() => setShowAddProductModal(false)}
                />
            )}
        </div>
    );
}
