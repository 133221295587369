import { dateTimeOldSystem } from 'utils/dateSchemas';
import z from 'zod';

export const QuoteSchema = z
    .object({
        id: z.number(),
        unique_id: z.string(),
        created_at: dateTimeOldSystem(),
        is_archived: z.number(),
        details: z.string().nullable(),

        final_price: z.array(
            z.object({
                cost: z.string().transform(val => (val ? parseFloat(val) : 0)),
                sub_total: z.string().transform(val => (val ? parseFloat(val) : 0)),
                tax: z.string().transform(val => (val ? parseFloat(val) : 0)),
                total: z.string().transform(val => (val ? parseFloat(val) : 0)),
                products_selected: z.string().transform(val => (val ? parseInt(val, 10) : 0)),
                products_selected_with_quantity: z
                    .string()
                    .transform(val => (val ? parseInt(val, 10) : 0)),
            }),
        ),
        children: z
            .array(
                z.object({
                    id: z.number(),
                    unique_id: z.string(),
                }),
            )
            .nullable(),
    })
    .transform(model => {
        return {
            ...model,
            isQuote: true as const,
            isPurchase: false as const,
        };
    });

export type Quote = z.infer<typeof QuoteSchema>;
