import MyEditModal from 'components/MyEditModal/MyEditModal';
import dealsApi from 'features/deals/deals.api';
import { DealDetail } from 'features/deals/models/DealDetail';
import React from 'react';
import DealEditFields from '../DealEditFields/DealEditFields';
import './DealEditModal.scss';

export default function DealEditModal({ model, close }: { model: DealDetail; close?: () => void }) {
    const [saveMutation, saveMutationState] = dealsApi.useDealUpdateMutation();
    const save = async (editModel: DealDetail) => {
        await saveMutation(editModel).unwrap();
        close?.();
    };

    const workflowDetailQuery = dealsApi.useWorkflowDetailQuery(model.context.workflow.id);

    return (
        <MyEditModal
            className="DealEditModal"
            model={model}
            isLoading={!model || workflowDetailQuery.isLoading}
            editImmediately={true}
            fullHeight={false}
            close={close}
            onSave={save}
            isSaving={saveMutationState.isLoading}
            title="Edit details"
            titleContext={model.tuid}
            mobileTitle={'Back'}
        >
            {({ editModel, updateField, isSaving }) => (
                <div className="DealEditModal__Details">
                    <div className="DealEditModal__Details__Main">
                        {workflowDetailQuery.data && (
                            <DealEditFields
                                editModel={editModel}
                                workflow={workflowDetailQuery.data}
                                phase={model.context.status.phase}
                                isSaving={isSaving}
                                updateField={updateField}
                            />
                        )}
                    </div>
                </div>
            )}
        </MyEditModal>
    );
}
