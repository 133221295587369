import DataTablePaging from 'components/DataTable/DataTablePaging';
import MyModal from 'components/MyModal/MyModal';
import MyTabs, { TabBuilder } from 'components/MyTabs/MyTabs';
import PageHeader from 'components/PageHeader/PageHeader';
import PropertyContainer from 'components/PropertyContainer/PropertyContainer';
import PropertyDisplay from 'components/PropertyDisplay/PropertyDisplay';
import { OrderProductRow } from 'features/orders/components/OrderProductRow/OrderProductRow';
import ordersApi from 'features/orders/orders.api';
import { ManufacturerOrder } from 'features/purchases/models/ManufacturerOrder';
import { ManufacturerOrderHistoryEntry } from 'features/purchases/models/ManufacturerOrderHistoryEntry';
import purchasesApi from 'features/purchases/purchases.api';
import useDynamicQueryParams from 'hooks/useDynamicQueryParams';
import React, { useMemo, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { formatDateRelative, formatDateTimeRelative } from 'utils/dateHelpers';
import ManufacturerOrderHistoryDisplay from '../ManufacturerOrderHistoryDisplay/ManufacturerOrderHistoryDisplay';
import { ManufacturerOrderStatusBadge } from '../ManufacturerOrderStatusBadge/ManufacturerOrderStatusBadge';
import './SupplierOrderModal.scss';

export default function SupplierOrderModal({
    model,
    isLoading,
    isError,
    close,
}: {
    model?: ManufacturerOrder;
    orderId: number;
    isLoading?: boolean;
    isError?: boolean;
    close?: () => void;
}) {
    const [tab, setTab] = useState('details');
    const tabs = useMemo(
        () =>
            model
                ? TabBuilder([
                      {
                          name: 'details',
                          label: 'Details',
                          content: <DetailTab model={model} />,
                      },
                      {
                          name: 'history',
                          label: 'History',
                          content: <HistoryTab model={model} />,
                      },
                  ])
                : [],
        [model],
    );

    return (
        <MyModal
            className="SupplierOrderModal"
            close={close}
            isLoading={isLoading}
            isError={isError}
            mobileTitle="Supplier Order"
            header={
                <PageHeader
                    title="Supplier Order"
                    titleContext={model?.manufacturer_reference}
                />
            }
        >
            <MyTabs
                activeTab={tab}
                tabs={tabs}
                setActiveTab={setTab}
            />
        </MyModal>
    );
}

function DetailTab({ model }: { model: ManufacturerOrder }) {
    const { orderId = '' } = useParams();
    const [urlParams] = useSearchParams();
    const orderQuery = purchasesApi.usePurchaseOrderDetailQuery(parseInt(orderId, 10));
    const productQuery = ordersApi.useOrderWindowListQuery(parseInt(orderId, 10));
    const products = useMemo(() => {
        return productQuery.currentData
            ?.flatMap(w => w.window_products)
            .filter(p => model.order_window_product_ids.includes(p.id));
    }, [model.order_window_product_ids, productQuery.currentData]);

    return (
        <>
            <PropertyContainer layout="table">
                <PropertyDisplay
                    label="Status"
                    value={
                        <div className="SupplierOrderModal__Status">
                            <ManufacturerOrderStatusBadge
                                manufacturerOrder={model}
                                showTooltip="statusOnly"
                                size="large"
                            />
                        </div>
                    }
                />

                <PropertyDisplay
                    label="ETA"
                    value={model.eta && formatDateRelative(model.eta)}
                />

                <PropertyDisplay
                    label="Notes"
                    verticalAlign="top"
                    value={model.customer_notes}
                />
                <PropertyDisplay
                    label="Last updated"
                    verticalAlign="top"
                    value={formatDateTimeRelative(model.updated_at)}
                />
            </PropertyContainer>
            <>
                <div>
                    <h2>Products</h2>
                </div>
                {orderQuery.data &&
                    products?.map(
                        p =>
                            orderQuery.data && (
                                <OrderProductRow
                                    key={p.id}
                                    order={orderQuery.data}
                                    product={p}
                                    to={`/purchase-orders/${orderId}/products/${p.id}?${urlParams}`}
                                />
                            ),
                    )}
            </>
        </>
    );
}

function HistoryTab({ model }: { model: ManufacturerOrder }) {
    const [queryParams, , , paging, setPaging] = useDynamicQueryParams(
        {
            manufacturerOrderId: model.id,
        },
        {
            pageSize: 40,
        },
    );
    const query = purchasesApi.useManufacturerOrderHistoryQuery(queryParams);

    return (
        <div className="SupplierOrderModal__HistoryTab">
            {query.data?.data.map((item: ManufacturerOrderHistoryEntry) => (
                <ManufacturerOrderHistoryDisplay
                    manufacturerId={model.manufacturer_id}
                    key={item.id}
                    item={item}
                />
            ))}
            {(query.data?.data.length ?? 0) > 0 && (
                <DataTablePaging
                    data={paging}
                    totalCount={query.data?.total}
                    onChange={setPaging}
                />
            )}
        </div>
    );
}
