import { dateFilterOptionsAny } from 'components/DataTableFilters/Filters/DateFilter';
import { SALES_REP_NOT_SET } from 'features/orders/orders.api';
import { QuoteListResult, QuoteListResultSchema } from 'features/quotes/models/QuoteListResult';
import { DynamicQueryParams } from 'models/DynamicQueryParams';
import { ApiTagType, api } from 'services/api';
import z from 'zod';
import QuoteStatus from './enums/QuoteStatus';
import { QuoteDetail, QuoteDetailSchema } from './models/QuoteDetail';
import { QuoteEditable } from './models/QuoteEditable';

export type QuoteListParams = DynamicQueryParams<{
    search?: string;
    date: string;
    status: string;
    archived: string;
    sales_rep_override?: string;
}>;

function buildOrderListFilters(params: QuoteListParams) {
    // filter by date - api requires this field so we default to last 30 days (-30)
    // but user can also select 'all' to remove the filter
    const dateVal = params.criteria.date || '-30';
    const dateOpt =
        dateVal === 'all' ? undefined : dateFilterOptionsAny.find(o => o.value === dateVal);

    const start = dateOpt?.minDate.toISO() ?? '2000-01-01';
    const end = dateOpt?.maxDate.toISO() ?? '2050-01-01';

    const status = params.criteria.status ?? '';
    const types = {
        quotes: !status || status === QuoteStatus.Quote,
        orders: !status || status === QuoteStatus.Ordered,
        drafts: false,
        draft_orders: false,
    };

    // to allow filtering by sales_rep_override === '' (not set)
    // we use a magic string SALES_REP_NOT_SET, which translates to undefined for the API call
    let sales_rep_override = params.criteria.sales_rep_override;
    if (sales_rep_override === SALES_REP_NOT_SET) {
        sales_rep_override = '';
    } else if (sales_rep_override === '') {
        sales_rep_override = undefined;
    }

    const filters = {
        search: params.criteria.search ?? '',
        start,
        end,
        is_archived: params.criteria.archived === 'true' ? 1 : 0,
        sales_rep_override,
        types,
    };

    return filters;
}

const quotesApi = api.injectEndpoints({
    endpoints: build => ({
        quoteList: build.query<QuoteListResult, QuoteListParams>({
            query: params => ({
                url: '/old/quotes/get',
                method: 'POST',
                data: {
                    filters: buildOrderListFilters(params),
                    pagination: {
                        limit: params.paging.limit,
                        offset: params.paging.skip,
                        // ordering: params.sort ? [params.sort] : [],
                    },
                },
            }),
            transformResponse: (result: unknown) => QuoteListResultSchema.parse(result),
            providesTags: [ApiTagType.QuoteList],
        }),

        quoteDetail: build.query<QuoteDetail, number>({
            query: id => ({
                url: `/old/quotes/${id}`,
                method: 'GET',
            }),
            transformResponse: (result: unknown) => {
                const schema = z.object({
                    data: QuoteDetailSchema,
                });
                const obj = schema.parse(result);
                return obj.data;
            },
            providesTags: (res, err, id) => [{ type: ApiTagType.QuoteDetail, id }],
        }),

        /** Create a new Draft Purchase Order */
        quoteCreate: build.mutation<number, QuoteEditable>({
            query: model => ({
                url: `/old/quotes`,
                method: 'POST',
                data: {
                    company_id: model.company_id,
                    employee_id: model.employee_id,
                    details: model.details,
                    is_draft: 0,
                },
            }),
            transformResponse: (result: unknown) => {
                const schema = z.object({
                    data: z.object({
                        id: z.number(),
                    }),
                });
                return schema.parse(result).data.id;
            },
            invalidatesTags: [
                ApiTagType.QuoteList,
                ApiTagType.DashboardData,
                ApiTagType.SalesRepList,
            ],
        }),

        /** Update an order - Quotes/Drafts only */
        quoteUpdate: build.mutation<void, QuoteEditable>({
            query: model => ({
                url: `/old/quotes/${model.id}/update-details`,
                method: 'POST',
                data: {
                    company_id: model.company_id,
                    details: model.details,
                    employee_id: model.employee_id,
                },
            }),
            async onQueryStarted(model, { dispatch, queryFulfilled }) {
                await queryFulfilled;

                // Pessimistic update of details object
                dispatch(
                    quotesApi.util.updateQueryData('quoteDetail', model.id, draft => {
                        // update all fields of the current detail model
                        Object.assign(draft, model);
                    }),
                );

                // invalidate list view
                dispatch(
                    quotesApi.util.invalidateTags([ApiTagType.QuoteList, ApiTagType.SalesRepList]),
                );
            },
        }),

        /** Apply a total price override to an an order - Quotes only */
        quoteApplyPriceOverride: build.mutation<
            void,
            {
                orderId: number;
                override_total: number | null;
            }
        >({
            query: args => ({
                url: `/old/quotes/${args.orderId}/manage-prices/apply-override-total`,
                method: 'POST',
                data: {
                    override_total: args.override_total,
                    notes: '',
                    override_taxables: null,
                },
            }),
            async onQueryStarted(args, { dispatch, queryFulfilled }) {
                await queryFulfilled;

                // Pessimistic update of details object
                dispatch(
                    quotesApi.util.updateQueryData('quoteDetail', args.orderId, draft => {
                        // update all fields of the current detail model
                        draft.final_price[0].override_total = args.override_total;
                    }),
                );

                // invalidate list view
                dispatch(quotesApi.util.invalidateTags([ApiTagType.QuoteList]));
            },
        }),

        /** Clone a quote */
        quoteClone: build.mutation<
            number,
            {
                orderId: number;
                details: string;
                windowIds: number[];
            }
        >({
            query: args => ({
                url: `/old/quotes/${args.orderId}/clone`,
                method: 'POST',
                data: {
                    type: 'whole_quote',
                    with_contact: 1,
                    details: args.details,
                    order_window_ids: args.windowIds,
                    first_run: true,
                    last_run: true,
                },
            }),
            transformResponse: (result: unknown) => {
                const schema = z.object({
                    cloned_order: z.object({
                        id: z.number(),
                    }),
                });
                return schema.parse(result).cloned_order.id;
            },
            invalidatesTags: [
                ApiTagType.QuoteList,
                ApiTagType.DashboardData,
                ApiTagType.SalesRepList,
            ],
        }),

        quoteArchive: build.mutation<void, number>({
            query: id => ({
                url: `/old/orders/archive/1`,
                method: 'POST',
                data: {
                    ids: [id],
                },
            }),
            invalidatesTags: (res, err, id) => [
                ApiTagType.QuoteList,
                ApiTagType.DashboardData,
                ApiTagType.SalesRepList,
                { type: ApiTagType.QuoteDetail, id },
            ],
        }),

        quoteUnarchive: build.mutation<void, number>({
            query: id => ({
                url: `/old/orders/archive/0`,
                method: 'POST',
                data: {
                    ids: [id],
                },
            }),
            invalidatesTags: (res, err, id) => [
                ApiTagType.QuoteList,
                ApiTagType.DashboardData,
                ApiTagType.SalesRepList,
                { type: ApiTagType.QuoteDetail, id },
            ],
        }),

        /** Get a PDF version of the order preview */
        quoteGeneratePdf: build.query<
            string,
            {
                orderId: number;
            }
        >({
            query: args => ({
                url: `/old/quotes/${args.orderId}/convert`,
                method: 'POST',
                data: getPdfGenerateConfig(),
            }),
            transformResponse: result => {
                const schema = z.object({
                    data: z.object({
                        document: z.object({
                            url: z.string(),
                        }),
                    }),
                });

                return schema.parse(result).data.document.url;
            },
        }),

        /** Submit quote and create and submit a purchase order to the manufacturer */
        quoteSubmit: build.mutation<
            void,
            {
                orderId: number;
                requiredDate: string;
                shippingAddress: string;
                shippingMethodId: number;
            }
        >({
            query: args => ({
                url: `/old/quotes/${args.orderId}/convert`,
                method: 'POST',
                data: {
                    type: 'order',
                    process_type: '',
                    orderOnline: true,
                    required_date: args.requiredDate,
                    shipping_address: args.shippingAddress,
                    shipping_method_id: args.shippingMethodId,
                },
            }),
            invalidatesTags: (res, err, args) => [
                ApiTagType.QuoteList,
                ApiTagType.DashboardData,
                { type: ApiTagType.QuoteDetail, id: args.orderId },
            ],
        }),
    }),
});

export default quotesApi;

/** Hard coded query data for generating pdfs
 * as specified in sc-41874
 */
function getPdfGenerateConfig() {
    return {
        preview_settings: {
            shipping_address: '',
            shipping_method_id: '',
            required_date: '',
            add_payment: 0,
            default_order_notes: '',
            default_measure_and_installation_notes: '',
            show_items_total: '1',
            show_installation_total: '1',
            show_shipping_total: '1',
            show_freight_total: '1',
            show_surcharge_total: '1',
            show_rep: '1',
            show_rep_name: '1',
            show_rep_email: '',
            show_rep_mobile: '',
            sub_total_label: 'Sub Total',
            total_label: 'Total',
            product_label: 'product',
            page_break: 'product',
            staff_position_name: 'Sales Rep',
            form_title: 'QUOTE',
            form_title_alignment: 'inherit',
            use_todays_date: '1',
            logo_position: 'Grid 1',
            logo_width: '',
            always_hide_sizes: '1',
            hide_sizes: '1',
            show_discount_amount: '1',
            show_quote_label: '',
            show_brand_name: '1',
            show_required_date: '1',
            show_shipping_details: '1',
            show_item_name: '1',
            show_quantity_site_mount: '1',
            show_po_number: '',
            show_sidemark: '1',
            show_additional_details: '',
            show_company_address: '1',
            show_business_name: '1',
            show_company_email: '1',
            show_company_abn: '1',
            show_company_acn: '1',
            show_other_address_details: '',
            show_sub_total: '1',
            show_total: '1',
            show_tax: '1',
            show_site_mount: '1',
            show_control: '0',
            show_control_length: '0',
            show_cost_price: '1',
            show_balance_due: '1',
            show_total_balance: '',
            hide_balance: '',
            show_deposit_due: '1',
            view_type: 'Consolidated',
            header_color: '#0db7dd',
            font_style: 'Lato',
            font_size: '10px',
            recipient: '',
            multiple_signatures: '',
            cover_page: '',
            rear_page: '',
            default_document: '',
            email_popup:
                '<p>Dear [first_name],</p>\n<p>Thank you for this opportunity to provide you with this quote.</p>\n<p>Please find attached a copy of our quote with details about the project. Please take a moment to look over the details.</p>\n<p>In order to proceed, we will require a [standard_deposit]% deposit of the quoted amount, once this is received we will be in contact with you to discuss the order process.</p>\n<p>Please confirm payment of your deposit by return email. Please use your quote number [order_number] and your name as the reference. Once payment has been received we will commence the manufacturing process of your order. </p>\n<p>We look forward to providing you with quality products and service.</p>\n<p>Should you have any questions about this quote, please contact myself.</p>',
            binding_text:
                '<p style="text-align: center;">By e-signing this document, you agree to our Terms &amp; Conditions and quoted price. Custom made products may not be cancelled or returned.</p>\n<p style="text-align: center;">Standard Deposit Terms:</p>\n<p style="text-align: center;">Upon acceptance a [standard_deposit]% deposit is required prior to ordering.</p>\n<p style="text-align: center;">Final Balance:</p>\n<p style="text-align: center;">The balance is payable on the day of installation.</p>\n<p style="text-align: center;">Payment via credit card will incur additional fees 1.5%</p>\n<p style="text-align: center;">Money transfer to the account below:<br /><br />Account Name:<br />Account No:</p>\n<p style="text-align: center;">BSB: <br />Reference: Please use your quote or order number</p>',
            priority_information: 'billing',
            priority_name: 'both',
            binding_text_sms:
                "Hi [first_name], Thank you for giving [company] the opportunity to quote for your window furnishing needs! We have just emailed your quote to your email address [email]. Please check your Junk Email folder if you can't see it in your inbox. For your reference, we have also added a link for your quote which you can access. [pdf]. Please feel free to contact us on [company_phone] if you have any questions.",
            discount_display: 'before_after',
            discount_display_label: 'Discount Applied',
            show_prices: '',
            show_custom_site_mount: '1',
            show_custom_measurement: '1',
            show_custom_quantity: '1',
            show_total_prices: 1,
        },
        type: 'quote',
        show_order_notes: '',
        show_installation_notes: '',
        show_binding_text: true,
        binding_text:
            '<p style="text-align: center;">By e-signing this document, you agree to our Terms &amp; Conditions and quoted price. Custom made products may not be cancelled or returned.</p>\n<p style="text-align: center;">Standard Deposit Terms:</p>\n<p style="text-align: center;">Upon acceptance a [standard_deposit]% deposit is required prior to ordering.</p>\n<p style="text-align: center;">Final Balance:</p>\n<p style="text-align: center;">The balance is payable on the day of installation.</p>\n<p style="text-align: center;">Payment via credit card will incur additional fees 1.5%</p>\n<p style="text-align: center;">Money transfer to the account below:<br /><br />Account Name:<br />Account No:</p>\n<p style="text-align: center;">BSB: <br />Reference: Please use your quote or order number</p>',
    };
}
