import Icons from 'Icons';
import MyButton, { MyButtonLink } from 'components/MyButton/MyButton';
import PageHeader from 'components/PageHeader/PageHeader';
import OrderPresentation from 'features/orders/providers/OrderPresentation';
import QuotesTable from 'features/quotes/components/QuotesTable/QuotesTable';
import React from 'react';
import { Outlet } from 'react-router';
import { usePageTitle } from 'utils/locationHelpers';

export default function QuotesPage() {
    usePageTitle('Quotes');
    return (
        <OrderPresentation isQuotes={true}>
            <PageHeader title="Quotes">
                <MyButton
                    label="New quote"
                    IconLeft={Icons.Plus}
                    buttonType="Accent"
                    href="new"
                    LinkComponent={MyButtonLink}
                />
            </PageHeader>
            <QuotesTable />
            <Outlet />
        </OrderPresentation>
    );
}
