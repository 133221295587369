import MyEditModal from 'components/MyEditModal/MyEditModal';
import PropertyContainer from 'components/PropertyContainer/PropertyContainer';
import PropertyEditText from 'components/PropertyEditText/PropertyEditText';
import { selectCurrentUser } from 'features/auth/auth.slice';
import { QuoteCreate, QuoteCreateFactory } from 'features/quotes/models/QuoteCreate';
import quotesApi from 'features/quotes/quotes.api';
import { selectCurrentCompany } from 'features/settings/settings.slice';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router';
import { useAppSelector } from 'store/hooks';
import './QuoteCreateModal.scss';

export default function QuoteCreateModal({ close }: { close?: () => void }) {
    const company = useAppSelector(selectCurrentCompany);
    const employee_id = useAppSelector(selectCurrentUser)?.id;
    const navigate = useNavigate();

    const model = useMemo(
        () =>
            company && employee_id
                ? QuoteCreateFactory.create({
                      is_draft: false,
                      company_id: company.id,
                      employee_id,
                  })
                : undefined,
        [company, employee_id],
    );

    const [saveMutation, saveMutationState] = quotesApi.useQuoteCreateMutation();

    const save = async (editModel: QuoteCreate) => {
        const id = await saveMutation(editModel).unwrap();
        navigate(`/quotes/${id}`);
    };

    return (
        <MyEditModal
            className="QuoteCreateModal"
            model={model}
            isLoading={!model}
            editImmediately={true}
            fullHeight={false}
            close={close}
            onSave={save}
            isSaving={saveMutationState.isLoading}
            title="New quote"
            mobileTitle="Quotes"
            saveButtonLabel="Create"
            saveButtonType="Accent"
        >
            {({ editModel, updateField, isSaving }) => (
                <>
                    <PropertyContainer>
                        <PropertyEditText
                            label="Details"
                            value={editModel.details ?? ''}
                            onChange={details => updateField({ details })}
                            multiline={true}
                            disabled={isSaving}
                        />
                    </PropertyContainer>
                </>
            )}
        </MyEditModal>
    );
}
