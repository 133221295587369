import { mergeDeep } from 'utils/helpers';

export type QuoteEditable = {
    id: number;
    company_id: number;
    employee_id: number | null;
    details: string | null;
};

export class QuoteEditableFactory {
    static create(
        data?: Partial<QuoteEditable> & { company_id: number; employee_id: number | null },
    ): QuoteEditable {
        const defaults: QuoteEditable = {
            id: 0,
            company_id: 0,
            employee_id: 0,
            details: '',
        };

        const mergedData = mergeDeep(defaults, data);

        return mergedData;
    }
}
