import { dateTimeOldSystem } from 'utils/dateSchemas';
import z from 'zod';
import ManufacturerOrderCoreStatus from '../enums/ManufacturerOrderCoreStatus';

export const ManufacturerOrderSchema = z.object({
    id: z.number(),
    manufacturer_id: z.number(),
    manufacturer_reference: z.string(),
    eta: z.string().nullable(),
    updated_at: dateTimeOldSystem({ isUTC: true }),
    customer_notes: z.string().nullable(),
    order_status_id: z.number().nullable(),
    customer_status: z.string().nullable(),
    order_status_text_color: z.string().nullable().default(null),
    order_status_background_color: z.string().nullable().default(null),
    core_status: z.nativeEnum(ManufacturerOrderCoreStatus).nullable(),
    order_window_product_ids: z.array(z.number()),
    total_freight: z
        .string()
        .nullable()
        .transform(val => (val ? parseFloat(val) : 0)),
    total_freight_override: z
        .string()
        .nullable()
        .transform(val => (val ? parseFloat(val) : 0)),
});

export type ManufacturerOrder = z.infer<typeof ManufacturerOrderSchema>;
