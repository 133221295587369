import { dateTimeOldSystem } from 'utils/dateSchemas';
import z from 'zod';

export const QuoteDetailSchema = z
    .object({
        id: z.number(),
        company_id: z.number(),
        employee_id: z.number().nullable(),
        unique_id: z.string(),
        created_at: dateTimeOldSystem(),
        installation_notes: z.string().nullable(),
        measure_notes: z.string().nullable(),
        is_archived: z.number(),

        details: z.string().nullable(),

        // product update stuff
        has_surcharges_updates: z.number(), // 0 or 1,
        freight_installation: z.object({
            has_freight_updates: z.number(), // 0 or 1,
            has_installation_updates: z.number(), // 0 or 1,
        }),
        order_window_products_that_has_updates: z.array(
            z.object({
                id: z.number(),
                has_cost_padding_updates: z.number(), // 0 or 1
                has_fabric_updates: z.number(), // 0 or 1
                has_freight_updates: z.number(), // 0 or 1
                has_installation_updates: z.number(), // 0 or 1
                has_rule_updates: z.number(), // 0 or 1
                has_surcharges_updates: z.number(), // 0 or 1
                has_updates: z.number(), // 0 or 1 - this means 'has_pricing_updates'
                product: z.object({
                    id: z.number(),
                    name: z.string(),
                }),
            }),
        ),

        final_price: z.array(
            z.object({
                cost: z.string().transform(val => (val ? parseFloat(val) : 0)),
                total: z.string().transform(val => (val ? parseFloat(val) : 0)),
                // sub_total: z.string().transform(val => (val ? parseFloat(val) : 0)),
                override_total: z
                    .string()
                    .nullable()
                    .transform(val => {
                        if (val === null) {
                            return null;
                        }
                        const num = parseFloat(val);
                        return Number.isNaN(num) ? null : num;
                    }),
                // tax: z.string().transform(val => (val ? parseFloat(val) : 0)),
                // total: z.string().transform(val => (val ? parseFloat(val) : 0)),
                products_selected: z.string().transform(val => (val ? parseInt(val, 10) : 0)),
                products_selected_with_quantity: z
                    .string()
                    .transform(val => (val ? parseInt(val, 10) : 0)),
            }),
        ),
        document_preview: z
            .array(
                z.object({
                    url: z.string(),
                }),
            )
            .optional(),
        children: z
            .array(
                z.object({
                    id: z.number(),
                    unique_id: z.string(),
                    is_active: z.number(),
                    converted_at: dateTimeOldSystem({ allowBlank: true }),
                    document_preview: z
                        .array(
                            z.object({
                                url: z.string(),
                            }),
                        )
                        .optional(),
                }),
            )
            .nullable(),
    })
    .transform(model => {
        const latestChild = model.children?.find(child => child.is_active === 1);

        const hasPendingUpdates =
            !latestChild &&
            (model.has_surcharges_updates === 1 ||
                model.freight_installation.has_freight_updates === 1 ||
                model.freight_installation.has_installation_updates === 1 ||
                (model.order_window_products_that_has_updates?.length ?? 0) > 0);

        return {
            ...model,
            hasPendingUpdates,
            pdfUrl: latestChild?.document_preview?.[0]?.url,
            isQuote: true as const,
            isPurchase: false as const,
            isReadOnly: hasPendingUpdates || model?.is_archived === 1,
        };
    });

export type QuoteDetail = z.infer<typeof QuoteDetailSchema>;
