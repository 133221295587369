import PropertyContainer from 'components/PropertyContainer/PropertyContainer';
import PropertyEditText from 'components/PropertyEditText/PropertyEditText';
import { QuoteEditable } from 'features/quotes/models/QuoteEditable';
import React from 'react';
import './QuoteEditFields.scss';

export default function QuoteEditFields({
    editModel,
    isSaving,
    updateField,
}: {
    editModel: QuoteEditable;
    isSaving: boolean;
    updateField: (data: Partial<QuoteEditable>) => void;
}) {
    return (
        <div className="QuoteEditFields">
            <PropertyContainer>
                <PropertyEditText
                    label="Details"
                    value={editModel.details ?? ''}
                    onChange={details => updateField({ details })}
                    multiline={true}
                    disabled={isSaving}
                />
            </PropertyContainer>
        </div>
    );
}
