import MyEditModal from 'components/MyEditModal/MyEditModal';
import { selectCurrentUser } from 'features/auth/auth.slice';
import { QuoteDetail } from 'features/quotes/models/QuoteDetail';
import { QuoteEditable, QuoteEditableFactory } from 'features/quotes/models/QuoteEditable';
import quotesApi from 'features/quotes/quotes.api';
import React, { useMemo } from 'react';
import { useAppSelector } from 'store/hooks';
import QuoteEditFields from '../QuoteEditFields/QuoteEditFields';
import './QuoteEditModal.scss';

export default function QuoteEditModal({
    order,
    close,
}: {
    order: QuoteDetail;
    close?: () => void;
}) {
    const [saveMutation, saveMutationState] = quotesApi.useQuoteUpdateMutation();

    const employee_id = useAppSelector(selectCurrentUser)?.id;

    const model = useMemo(
        () =>
            QuoteEditableFactory.create({
                id: order.id,
                company_id: order.company_id,
                employee_id: order.employee_id ?? employee_id ?? null,
                details: order.details,
            }),
        [employee_id, order],
    );

    const save = async (editModel: QuoteEditable) => {
        await saveMutation(editModel).unwrap();
        close?.();
    };

    return (
        <MyEditModal
            className="QuoteEditModal"
            model={model}
            isLoading={!model}
            editImmediately={true}
            fullHeight={false}
            close={close}
            onSave={save}
            isSaving={saveMutationState.isLoading}
            title="Edit details"
            titleContext={order.unique_id}
            mobileTitle="Quote"
        >
            {({ editModel, updateField, isSaving }) => (
                <QuoteEditFields
                    editModel={editModel}
                    isSaving={isSaving}
                    updateField={updateField}
                />
            )}
        </MyEditModal>
    );
}
